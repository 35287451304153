import React, { Component } from 'react';

import CollectionList from './CollectionList';

class CollectionsPage extends Component {

  render() {
    const { identity } = this.props;
    return (
      <div className="main-content full-width">
        <CollectionList company_id={'SUPPLIER' === identity.company_type ? identity.company_id : null} />
      </div>
    );
  }
};

export default CollectionsPage;
