import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import CollectionsApp from '../containers/CollectionsApp';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ show }) => {
  const params = useParams();
  const location = useLocation();

  if (show) {
    return <CollectionsApp params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const CollectionsRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/collections.php"
        element={<AppRoute show />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default CollectionsRouter;
